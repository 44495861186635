@import './variables.scss';

@function rem($pixels) {
  @if (unitless($pixels)) {
    $pixels: $pixels * 1px;
  }
  
  @return $pixels / 16px * 1rem;
}

@function spacing($n) {
  @return $spacing-base * $n;
}
