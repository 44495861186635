@import '../../styles/functions.scss';

.edit-page {
  height: 100vh;
  color: white;

  .cancel-button {
    position: absolute;
    left: rem(20);
    top: rem(20);
    border: none;
    padding: 0;
    background: transparent;

    svg {
      width: rem(30);
      height: rem(30);

      circle {
        fill: rgba(255, 255, 255, 0.7);
      }

      path {
        fill: #55366f;
      }
    }
  }

  &::after {
    background: purple;
    background-image: url('../../images/backgroundimage.jpg');
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    content: '';
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
  }

  h2 {
    font-size: rem(30);
    text-align: center;
    margin-top: 0;
    padding-top: rem(25);
  }

  hr {
    width: 60%;
    margin: rem(15) auto;
  }

  p {
    padding: 0 rem(15);
    font-size: rem(24);
  }
}

.edit-page {
  .form {
    display: flex;
    flex-direction: column;
    padding: 0 rem(15);
    font-size: rem(20);

    button {
      display: inline-block;
      width: fit-content;
      margin: rem(15) rem(0);
      border: rem(1) solid white;
      border-radius: rem(4);
      padding: rem(5);
      background-color: transparent;
      appearance: none;
      color: white;
    }
  }

  input,
  textarea {
    color: white;
    border: 1px solid white;
    background-color: transparent;
    padding: rem(5);
  }

  .input-name {
    text-align: center;
    margin: rem(30) auto 0;
    width: 70%;
    font-size: rem(30);
  }

  .input-group-ingredient {
    display: grid;
    grid-template-columns: 35% 60%;
    margin: rem(5) 0;
    width: 100%;
    justify-content: space-between;

    .input-ingredient-quantity {
      grid-area: quantity;
      grid-column: 1;
      grid-row: 1;
    }

    .input-ingredient-name {
      grid-area: name;
      grid-column: 2;
      grid-row: 1;
    }
  }
}
