@import '../../styles/functions.scss';

.list-component {
  height: 100vh;
  color: white;

  &::after {
    background: purple;
    background-image: url('../../images/backgroundimage.jpg');
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    content: '';
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .list-item {
    display: block;
    z-index: 100;
    padding: rem(10) rem(15) rem(2);
    border-bottom: rem(1) solid rgba(255, 255, 255, 0.8);

    &:last-child {
      border-bottom: 0;
      margin-bottom: rem(50);
    }

    h2 {
      font-size: rem(30);
      line-height: 1.5;
      padding: 0;
      margin: 0;
    }

    p {
      margin: 0;
      color: rgba(255, 255, 255, 0.8);
      font-size: rem(18);
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .new-button {
    display: inline-block;
    margin: rem(5) rem(15);
    border: rem(1) solid white;
    border-radius: rem(4);
    padding: rem(5);
    font-size: rem(20);

    &:last-of-type {
      margin: rem(15) rem(15) rem(20);
    }
  }
}
