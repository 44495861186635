@import '../../node_modules/normalize.css/normalize.css';
@import './mixins.scss';
@import './functions.scss';

body {
  font-family: 'AvenirNextCondensed', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  font-size: rem(16);

  @include mobile {
    font-size: rem(14);
  }

}

* {
  outline: none;
}

button {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}
