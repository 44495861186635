@font-face {
    font-family: 'AvenirNextCondensed-Bold';
    src: url('./fonts/avenir-next-condensed/AvenirNextCondensed-Bold.ttf');
}

@font-face {
    font-family: 'AvenirNextCondensed';
    src: url('./fonts/avenir-next-condensed/AvenirNextCondensed-Regular.ttf');
}

@font-face {
    font-family: 'AvenirNextCondensed-DemiBold';
    src: url('./fonts/avenir-next-condensed/AvenirNextCondensed-DemiBold.ttf');
}

@font-face {
    font-family: 'AvenirNextCondensed-Medium';
    src: url('./fonts/avenir-next-condensed/AvenirNextCondensed-Medium.ttf');
}

@font-face {
    font-family: 'AvenirNextCondensed-UltraLight';
    src: url('./fonts/avenir-next-condensed/AvenirNextCondensed-UltraLight.ttf');
}
