@import './variables.scss';

@mixin mobile() {
  @media only screen and (max-width: $mobile-end) {
    @content;
  }
}

@mixin desktop() {
  @media only screen and (min-width: $mobile-end) {
    @content;
  }
}

@mixin hover() {
  &:hover, &:focus {
    @content;
  }
}
