@import '../../../styles/functions.scss';

.search-bar-component {
  padding: rem(10) 0;

  input {
    display: block;
    border: none;
    border-bottom: 1px solid rgba(255,255,255,.8);
    background-color: transparent;
    width: 80%;
    margin: 0 auto;
    font-size: rem(24);
    color: rgba(255,255,255,.8);
    padding-left: rem(10);
    padding-bottom: rem(5);
    border-radius: 0;
    &::placeholder {
      color: rgba(255,255,255,.8);
    }
  }
}
