@import '../../styles/functions.scss';

.details-page {
  height: 100vh;
  color: white;

  .back-button {
    position: absolute;
    left: rem(20);
    top: rem(20);
    border: none;
    padding: 0;
    background: transparent;

    svg {
      width: rem(30);
      height: rem(30);

      circle {
        fill: rgba(255, 255, 255, 0.7);
      }

      path {
        fill: #55366f;
      }
    }
  }

  .edit-button {
    position: absolute;
    right: rem(20);
    top: rem(20);
    border: none;
    padding: 0;
    background: transparent;

    svg {
      transition: 300ms;
      width: rem(30);
      height: rem(30);
      fill: white;

      circle {
        fill: rgba(255, 255, 255, 0.7);
      }

      path {
        fill: #55366f;
      }
    }
  }

  .remove-button {
    position: absolute;
    right: rem(20);
    top: rem(60);
    border: none;
    padding: 0;
    background: transparent;

    svg {
      transition: 300ms;
      transform: scale(1);
      width: rem(30);
      height: rem(30);

      circle {
        fill: rgba(255, 255, 255, 0.7);
      }

      path {
        fill: #55366f;
      }
    }

    &.active {
      svg {
        transform: scale(1.2);

        circle {
          fill: #e60000;
        }

        path {
          fill: #fff;
        }
      }
    }
  }

  &::after {
    background: purple;
    background-image: url('../../images/backgroundimage.jpg');
    background-size: cover;
    position: fixed;
    width: 100vw;
    height: 100vh;
    content: '';
    display: block;
    top: 0;
    left: 0;
    z-index: -1;
  }

  h2 {
    font-size: rem(30);
    text-align: center;
    margin-top: 0;
    padding-top: rem(25);
  }

  hr {
    width: 60%;
    margin: rem(15) auto;
  }

  p {
    padding: 0 rem(15);
    font-size: rem(24);
  }
}

.details-page {
  .form {
    display: flex;
    flex-direction: column;
    padding: 0 rem(15);
  }

  input,
  textarea {
    color: white;
    border: 1px solid white;
    background-color: transparent;
  }

  .input-name {
    text-align: center;
    margin: rem(30) auto 0;
    width: 70%;
    font-size: rem(30);
  }

  .input-group-ingredient {
    display: flex;
    margin: rem(5);

    .input-ingredient-quantity {
      flex: 1;
      margin-right: rem(15);
    }

    .input-ingredient-name {
      flex: auto;
    }
  }
}
